import React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  ACCOMODATIONS_DAYS,
  ACCOMODATIONS_DAYS_RO,
  DataOpeningDays,
} from "stores/queries/accomodations";
import { OpeningTable } from "components/List/OpeningTable";
import { formatDate } from "libs/helpers";
import { secureInfos } from "stores/db/appstate";

interface AccomodationOpeningInfosProps {
  area: string;
}

const AccomodationOpeningInfos = (props: AccomodationOpeningInfosProps) => {
  const [getDays, { loading, error, data }] = useLazyQuery(
    ACCOMODATIONS_DAYS_RO,
    { context: { headers: { areaSecureCode: `${secureInfos()}` } } }
  );

  const getData = async (
    from: string,
    to: string
  ): Promise<DataOpeningDays> => {
    let days = await getDays({
      variables: {
        from: formatDate(new Date(from)),
        to: formatDate(new Date(to)),
        area: props.area,
      },
    });
    let data: DataOpeningDays = [];
    if (days?.data?.roAccomodationsGetDays) {
      for (let i = 0; i < days?.data?.roAccomodationsGetDays.length; i++) {
        const d = days?.data?.roAccomodationsGetDays[i];
        const name =
          (d.name?.find((n) => n.langIso === "it")?.value ||
            d.name[0]?.value ||
            "") +
          " " +
          "*".repeat(d.stars) +
          " (" +
          d.beds +
          ")";

        data.push({
          _id: d._id,
          name,
          days: d.days,
          stars: d.stars,
          beds: d.beds,
          townId: d.townId,
          rooms: d.rooms,
        });
      }
    }
    return data;
  };

  return (
    <OpeningTable
      getData={getData}
      loading={loading}
      title={"Apertura Strutture Ricettive"}
      itemPath={"/accomodations_openings/"}
      icon="my_hotel"
      type="accomodation"
      scope="info"
      area={props.area}
    />
  );
};

export { AccomodationOpeningInfos };
