import {
  AlertIcon,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Circle,
  Divider,
  Flex,
  Hide,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Switch,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import {
  CalendarIcon,
  CheckIcon,
  EmailIcon,
  ExternalLinkIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import styled from "@emotion/styled";

import { AddRangeDrawer } from "components/Calendar/AddRangeDrawer";
import Select from "react-select";
import { Resource } from "stores/queries/area";
import theme from "theme/theme";
import { MowiIcon, MowiIcons } from "ui/MowiIcon/Icon";
import { AddRangeMutation } from "components/Poi/types";
import { SearchBar } from "./SearchBar";
import { STORED_POI_FILTERS } from "stores/db/filtersStore";
import { IoWarning } from "react-icons/io5";
import { Loading } from "ui/Loading/Loading";
import { CgGoogle } from "react-icons/cg";

const filtersData = [
  "ristoranti-e-pizzerie",
  "borghi-e-localita-turistiche",
  "cultura",
  "attivita-outdoor",
  "centri-fondo",
  "sulla-neve",
  "farmacie-e-numeri-utili",
  "servizi-utili",
  "natura",
  "punti-panoramici",
  "scuole-di-sci",
  "centri-benessere",
  "wellness",
  "grande-guerra",
  "botteghe-e-negozi",
  "shopping",
  "produzioni-agricole",
  "guide-mtb",
  "in-bici",
  "chiese-e-santuari",
  "a-piedi",
  "falesie",
  "caseifici",
  "parchi-naturali-e-aree-protette",
  "bar",
  "ski-area",
  "rifugi-e-ristoranti-in-quota",
  "altre-attivita",
  "parchi-gioco",
  "e-bike-e-bici",
  "noleggi",
  "piste-da-slittino",
  "laghi-balneabili-e-spiagge",
  "luoghi-incantevoli",
  "musei-ed-ecomusei",
  "campi-da-calcio-e-palazzetti",
  "enoteche-e-birrifici",
  "laghetti-alpini-e-cascate",
  "pasticcerie",
  "malghe",
  "agriturismi",
  "piscine",
  "fattorie-didattiche",
  "agritur-e-malghe",
  "cammino",
  "giardini-e-orti-botanici",
  "golf",
  "sci-e-ciaspole",
  "impianti-di-risalita",
  "cantine-e-distillerie",
  "parco-giochi-sulla-neve",
  "salumifici-e-macellerie-e-troticolture",
  "downhill-bike-park",
  "panifici-e-pasticcerie",
  "maneggi",
  "piste-per-pattinaggio",
  "snowpark",
  "castelli-e-palazzi",
  "percorsi-kneipp-e-sensoriali",
  "parapendio",
  "altre-attrezzature-sportive",
  "taxi-e-servizi-con-conducente",
  "trasporti",
  "guide-alpine-e-accompagnatori",
  "terme",
  "rafting-e-hydrospeed",
  "sull-acqua",
  "arrampicata-indoor",
  "skibus-e-navette",
  "parchi-avventura",
  "trekking-ed-escursioni",
  "kayak-canoa-e-sup",
  "ispirazione",
  "bicigrill",
  "mountain-bike",
  "enoteche-e-birrerie",
  "autofficine",
  "mobilita",
  "dolomiti-unesco",
  "artigianato",
  "canyoning",
  "itinerari",
  "navigazione",
  "canyon-e-grotte",
];

type ReactSelectOption = {
  value: string;
  label: string;
};
const options: ReactSelectOption[] = [];

type TableRow = {
  id: string;
  name: string;
  status?: (string | boolean | undefined)[];
  lastUpdate?: string;
  updatedBy?: string;
  warnings: number;
  operator: [
    {
      manual?: boolean;
      calendar?: boolean;
    },
    string,
    string,
    string | undefined,
    {
      threeMonthAlertSent: boolean;
      threeMonthAlertDate: string;
      emptyCalendarAlertSent: boolean;
      emptyCalendarAlertDate: string;
    },
    googleSync: boolean
  ];
  cms: string;
  filters: string[];
  search: string;
  email: string;
  category?: string;
  lastCheckDate: string;
  locality: any;
};

// for (let index = 0; index < filtersData.length; index++) {
//   options.push({ value: filtersData[index], label: filtersData[index] });
// }
filtersData.sort().forEach((filter) => {
  options.push({ value: filter, label: filter });
});

let tbColumn = [
  {
    Header: "ID",
    accessor: "id",
    disableSortBy: true,
  },
  {
    Header: "CHECK",
    disableSortBy: true,
  },
  {
    Header: "NOME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "LOCALITA",
    accessor: "locality",
  },
  {
    Header: "CATEGORIA",
    accessor: "category",
  },
  {
    Header: "STATO",
    accessor: "status",
    disableSortBy: true,
  },
  {
    Header: "ULTIMA DATA CALENDARIO",
    accessor: "lastUpdate",
  },
  /*   {
    Header: "CHECK OPERATORE",
    accessor: "lastCheckDate",
  }, */
  /*   {
    Header: "AVVISI",
    accessor: "warnings",
  }, */
  {
    Header: "ULTIMA MODIFICA",
    accessor: "updatedBy",
  },
  {
    Header: "OPERATORE",
    accessor: "operator",
    disableSortBy: true,
  },
  /*   {
    Header: "CMS",
    accessor: "cms",
    disableSortBy: true,
  }, */
] as const satisfies Column<TableRow>[];

type Header = (typeof tbColumn)[number]["Header"];

export const StyleWrapper = styled.div`
  .basic-multi-select {
    display: flex;
    flex: 1;
    margin-right:10,
    margin-left:10,
    font-family: Roboto
    overflow: hidden;
  }
  .select__control--is-focused {
    background-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px !important;
    border-radius: 20px;
    box-shadow: 0 0 0 0px rgb(244, 247, 254);
  }

  .select__control--menu-is-open {
    background-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px !important;
    border-radius: 20px;
  }

  .select__control {
    width:100%;
    background-color: rgb(244, 247, 254);
    border-color: rgb(244, 247, 254);
    border-radius: 20px;
    border-width:0px;
    border-radius: 20px;
  }
  .select__menu {
    z-index:99999999
  }

  .select__multi-value {
    background-color: ${theme.colors.gray[400]};
    border-radius:10px;
    overflow: hidden;
  }
  .select__multi-value__label, .select__multi-value__remove {
    color: #fff
  }

  .select__option {
    font-size: 14px;
  }

  .select__placeholder {
    color: ${theme.colors.gray[400]} !important;
  } 
`;

type GetQrMutation = ({
  variables,
}: {
  variables: {
    _ids: string | string[];
  };
}) => Promise<any>;
type SecureManageMutation = ({
  variables,
}: {
  variables: {
    _ids: string | string[];
    secure: {
      reNewCode: boolean;
      permissions: {
        manual: boolean;
        calendar: boolean;
      };
    };
  };
}) => Promise<any>;

interface ResourceTableProps {
  data: Resource[];
  addRange: AddRangeMutation;
  secureManage: SecureManageMutation;
  getQrEmail: GetQrMutation;
  title: string;
  loading: any;
  itemPath: string;
  icon: MowiIcons;
  type: "track" | "transport" | "poi" | "accomodation";
}

const HIDDEN_COLS: {
  [key in ResourceTableProps["type"]]: Header[];
} = {
  track: ["EMAIL"],
  transport: ["EMAIL", "CATEGORIA"],
  poi: ["CATEGORIA", "LOCALITA"],
  accomodation: ["STATO"],
};

const translateUpdateSource = (source: string) => {
  const src = source.toLowerCase();
  switch (src) {
    case "admin":
      return "APT";
    case "operator":
      return "Operatore";
    default:
      return source;
  }
};

const ResourceTable = (props: ResourceTableProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const successToast = (message = "Operazione completata") =>
    toast({
      title: message,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  const errorToast = (message = "Operazione fallita") =>
    toast({
      title: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [tableFilteredData, setTableFilteredData] = useState<TableRow[]>([]);
  const [storeInitialized, setStoreInitialized] = useState(false);

  /*   const { value, setValue, getCheckboxProps } = useCheckboxGroup(); */
  const [selected, setSelected] = useState<string[]>([]); //IDs
  const allSelected = !!(
    selected.length &&
    tableFilteredData.length &&
    selected.length === tableFilteredData.length
  );

  const [permissions, setPermissions] = useState({
    calendar: false,
    manual: false,
  });

  const [strSearch, setStrSearch] = useState("");
  const [filters, setActualFilters] = useState<readonly ReactSelectOption[]>(
    []
  );
  const storeFilters = (filters: readonly ReactSelectOption[]) => {
    if (props.type == "poi") {
      STORED_POI_FILTERS.category(filters);
    }
  };
  const storeSearch = (search: string) => {
    if (props.type == "poi") {
      STORED_POI_FILTERS.search(search);
    }
  };

  const searchTimeout = useRef(null);
  const onSearchChange = (search: string) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      setStrSearch(search);
      storeSearch(search);
    }, 300);
  };

  const setFilters = (newFilters: readonly ReactSelectOption[]) => {
    setActualFilters(newFilters);
    storeFilters(newFilters);
  };

  React.useEffect(() => {
    if (props.type === "poi") {
      setStrSearch(STORED_POI_FILTERS.search());
      setActualFilters(STORED_POI_FILTERS.category());
      setStoreInitialized(true);
    } else if (props.type === "accomodation") {
      setStoreInitialized(true);
    }
  }, []);
  React.useEffect(() => {
    if (!storeInitialized) return;
    calcFilteredData(filters, strSearch);
  }, [filters, strSearch, tableData, storeInitialized]);

  const calcFilteredData = React.useCallback(
    (newFilters: readonly ReactSelectOption[], newSearch?: string) => {
      if (newSearch && newSearch.length > 2) {
        let filteredItems = tableData.filter((row) => {
          let inFilters = true;
          if (newFilters?.length > 0)
            inFilters = newFilters.some((filter) =>
              row.filters?.includes(filter.value)
            );
          if (inFilters) {
            return row.search.indexOf(newSearch) > -1;
          } else {
            return null;
          }
        });

        setTableFilteredData(filteredItems);
        setSelected([]);
      } else if (newFilters?.length > 0) {
        const filteredItems = tableData.filter((obj) =>
          newFilters.some((filter) => obj.filters?.includes(filter.value))
        );
        setTableFilteredData(filteredItems);
        setSelected([]);
      } else if (tableData.length > 0) {
        if (searchTimeout.current) {
          clearTimeout(searchTimeout.current);
        }
        setTableFilteredData(tableData);
        setSelected([]);
      }
    },
    [tableData]
  );

  useEffect(() => {
    if (!props.data) return;
    let resourcesArr: TableRow[] = [];

    for (let i = 0; i < props.data.length; i++) {
      let category = null;
      const resource = props.data[i];

      let status = "N.D.";
      const { lastDay, updatedBy, lastUpdate } = resource.calendarMetadata;
      const calendarUpdate = lastDay
        ? new Date(lastDay).toLocaleDateString()
        : "-";
      let calendarUpdateBy = updatedBy ? translateUpdateSource(updatedBy) : "-";

      calendarUpdateBy =
        calendarUpdateBy +
        (lastUpdate ? " - " + new Date(lastUpdate).toLocaleDateString() : "");

      let name =
        resource.name?.find((n) => n.langIso === "it")?.value ||
        resource.name[0]?.value ||
        "";

      if (resource.accomodationMetadata) {
        category = resource.accomodationMetadata?.stars ? "HOTEL" : "ALTRO";
        name +=
          " " +
          "*".repeat(resource.accomodationMetadata?.stars || 0) +
          " (" +
          resource.accomodationMetadata?.beds +
          " letti)";
      } else {
        category = resource.category;
      }

      if (typeof resource.statuses?.live?.status === "boolean") {
        if (resource.statuses?.live?.updatedOrigin === "CALENDAR") {
          status = "CAL";
        } else if (resource.statuses?.live?.updatedOrigin === "EXTERNAL") {
          status = "EXT";
        } else if (resource.statuses?.live?.updatedOrigin === "MANUAL") {
          status = "MAN";
        }

        // let name = "";

        // if (resource.name && resource.name[0]) {
        //   name = resource.name[0].value;
        // }

        resourcesArr.push({
          id: resource._id,
          name,
          status: [status, resource?.statuses?.live?.status],
          lastUpdate: calendarUpdate,
          updatedBy: calendarUpdateBy,
          warnings: resource?.eventsCount || 0,
          operator: [
            resource?.secure?.permissions,
            // `./?s=${resource?.secure?.code}&t=${props.type}&i=${resource._id}`,
            "",
            resource?.externalInfo?.updateLink,
            resource.emailSent
              ? new Date(resource.emailSent).toLocaleDateString()
              : undefined,
            {
              emptyCalendarAlertSent:
                resource.calendarMetadata?.emptyCalendarAlertSent,
              emptyCalendarAlertDate:
                resource.calendarMetadata?.emptyCalendarAlertDate,
              threeMonthAlertDate:
                resource.calendarMetadata?.threeMonthAlertDate,
              threeMonthAlertSent:
                resource.calendarMetadata?.threeMonthAlertSent,
            },
            resource.calendarSync?.googleAccountId ? true : false,
          ],
          cms: "",
          filters: resource.filters,
          search: name.toLowerCase(),
          email: resource.email,
          category,
          lastCheckDate: resource.calendarMetadata?.lastOperatorCheck
            ? new Date(
                resource.calendarMetadata?.lastOperatorCheck
              ).toLocaleDateString()
            : "-",
          locality: resource.accomodationMetadata?.townId,
        });
      } else {
        resourcesArr.push({
          id: resource._id,
          name: name,
          status: [status, resource?.statuses?.live?.status],
          lastUpdate: calendarUpdate,
          updatedBy: calendarUpdateBy,
          warnings: resource?.eventsCount || 0,
          operator: [
            resource?.secure?.permissions,
            // `./?s=${resource?.secure?.code}&t=${props.type}&i=${resource._id}`,
            "",
            resource?.externalInfo?.updateLink,
            resource.emailSent
              ? new Date(resource.emailSent).toLocaleDateString()
              : undefined,
            {
              emptyCalendarAlertSent:
                resource.calendarMetadata?.emptyCalendarAlertSent,
              emptyCalendarAlertDate:
                resource.calendarMetadata?.emptyCalendarAlertDate,
              threeMonthAlertDate:
                resource.calendarMetadata?.threeMonthAlertDate,
              threeMonthAlertSent:
                resource.calendarMetadata?.threeMonthAlertSent,
            },
            resource.calendarSync?.googleAccountId ? true : false,
          ],
          cms: "",
          filters: resource.filters,
          search: name.toLowerCase(),
          email: resource.email,
          category,
          lastCheckDate: resource.calendarMetadata?.lastOperatorCheck
            ? new Date(
                resource.calendarMetadata?.lastOperatorCheck
              ).toLocaleDateString()
            : "-",
          locality: resource.accomodationMetadata?.townId,
        });
      }
    }

    let sortedArr = resourcesArr.sort((a, b) => a.name.localeCompare(b.name));

    setTableData(sortedArr);
    setTableFilteredData(sortedArr);
  }, [props.data]);

  /*   if (props.type === "accomodation") {
    tbColumn.splice(5, 1);
  } */

  const tableInstance = useTable<TableRow>(
    {
      columns: tbColumn,
      data: tableFilteredData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
  } = tableInstance;
  initialState.pageSize = 10;

  const hiddenColSM = [
    "ID",
    "EMAIL",
    "CHECK",
    "CHECK OPERATORE",
    "ULTIMA DATA CALENDARIO",
    "ULTIMA MODIFICA",
  ];

  const { pageIndex, pageSize } = state;

  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        overflowY={"hidden"}
        paddingBottom={"0px !important"}
        bg={"white"}
        borderRadius={30}
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        <Flex px="25px" justify="space-between" mt={5} mb={5} align="center">
          <Flex align="center" color={"brand.500"}>
            <MowiIcon name={props.icon} size={30} />
            <Text
              color={"gray.700"}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              {props.title}
            </Text>
          </Flex>

          {props.type === "poi" && (
            <Hide below="md">
              <Flex style={{ flex: 1 }} mx={5}>
                <StyleWrapper style={{ flex: 1 }}>
                  <Select
                    placeholder={"Filtri categoria..."}
                    isMulti
                    name="categories"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={setFilters}
                    value={filters}
                  />
                </StyleWrapper>
              </Flex>
            </Hide>
          )}

          {props.type === "accomodation" && (
            <Flex style={{ flex: 1 }} mx={5}></Flex>
          )}

          <SearchBar
            value={strSearch}
            setSearch={onSearchChange}
            transform={(str) => str.toLowerCase()}
          />

          {props.type === "accomodation" && (
            <ButtonGroup ml={3} spacing="2">
              <Button
                as={RouterLink}
                to={"/accomodations/openings"}
                onClick={() => {}}
                colorScheme="brand"
                variant={"outline"}
              >
                Aperture
              </Button>
            </ButtonGroup>
          )}

          {/*  <Menu /> */}
        </Flex>
        <Flex maxHeight={"calc(100vh - 340px)"} overflowY={"auto"}>
          <Table
            key={props.type + "_table"}
            {...getTableProps()}
            variant="striped"
          >
            <Thead bgColor={"white"} position="sticky" top={0} zIndex="docked">
              {headerGroups.map((headerGroup, index) => (
                <Tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={index + "_tr_" + props.type}
                >
                  {headerGroup.headers.map((column, index) =>
                    HIDDEN_COLS[props.type].includes(
                      column.Header as Header
                    ) ? undefined : (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        pe="10px"
                        key={index + "_th_" + props.type}
                        borderColor={"gray.200"}
                        className={
                          hiddenColSM.includes(String(column.Header))
                            ? "hidden-sm"
                            : undefined
                        }
                        style={{
                          display: index === 0 ? "none" : undefined,
                          width: index === 1 ? 20 : undefined,
                          paddingLeft: index === 1 ? 40 : undefined,
                          cursor: column?.canSort ? "pointer" : undefined,
                        }}
                      >
                        <Flex
                          justify="flex-start"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {index === 1 ? (
                            <Checkbox
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  let arrSelect = tableFilteredData.map(
                                    (resource) => resource.id
                                  );
                                  setSelected(arrSelect);
                                } else {
                                  setSelected([]);
                                }
                              }}
                              isChecked={allSelected}
                              //defaultChecked={cell.value[2]}
                              colorScheme="brandScheme"
                            />
                          ) : (
                            column.render("Header")
                          )}{" "}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "↓"
                              : "↑"
                            : ""}
                        </Flex>
                      </Th>
                    )
                  )}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index + "_trr_" + props.type}>
                    {row.cells.map((cell, index) => {
                      let data;
                      if (
                        HIDDEN_COLS[props.type].includes(
                          cell.column.Header as Header
                        )
                      )
                        return null;
                      if (cell.column.Header === "NOME") {
                        data = (
                          <Flex direction={"row"} align="center">
                            <Link
                              as={RouterLink}
                              to={props.itemPath + row.cells[0].value}
                              noOfLines={1}
                            >
                              {cell.value}
                            </Link>
                          </Flex>
                        );
                      } /* else if (cell.column.Header === "CATEGORIA") {
                        data = (
                          <>
                            {cell.value &&
                              cell.value.map((res: string, index: number) => {
                                return (
                                  <Button
                                    key={
                                      "filter" +
                                      row.cells[0].value +
                                      "-" +
                                      index
                                    }
                                    onClick={() => {
                                      setStrSeach(res.toLowerCase());
                                    }}
                                    variant={"link"}
                                  >
                                    {res}
                                  </Button>
                                );
                              })}
                          </>
                        );
                      } */ else if (cell.column.Header === "STATO") {
                        let color = "pink";
                        if (typeof cell.value[1] === "boolean") {
                          if (cell.value[1]) color = "green";
                        } else color = "gray";
                        data = (
                          <Tag
                            width={"100%"}
                            textAlign={"center"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            size={"sm"}
                            variant="solid"
                            colorScheme={color}
                          >
                            {cell.value[0]}
                          </Tag>
                        );
                      } else if (cell.column.Header === "AVVISI") {
                        data = (
                          <Tag
                            width={"100%"}
                            textAlign={"center"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            size={"sm"}
                            variant="solid"
                            bgColor={cell.value ? "yellow.500" : "gray.200"}
                            color={cell.value ? "white" : "gray.600"}
                          >
                            {cell.value ? cell.value : "/"}
                          </Tag>
                        );
                      } else if (cell.column.Header === "OPERATORE") {
                        const values = cell.value as TableRow["operator"];
                        data = (
                          <HStack>
                            {values[2] && (
                              <>
                                <Link
                                  href={values[2]}
                                  isExternal
                                  whiteSpace="nowrap"
                                  _hover={{
                                    opacity: 0.7,
                                  }}
                                >
                                  Adobe <ExternalLinkIcon mb="1" />
                                </Link>
                              </>
                            )}
                            {values[0].manual && (
                              <Tooltip label="Permessi stato in tempo reale">
                                <Circle fontSize={10} size={5} bg={"green.500"}>
                                  <TimeIcon fontSize={9} color={"white"} />
                                </Circle>
                              </Tooltip>
                            )}
                            {values[0].calendar && (
                              <Tooltip label="Permessi calendario">
                                <Circle fontSize={10} size={5} bg={"green.500"}>
                                  <CalendarIcon fontSize={8} color={"white"} />
                                </Circle>
                              </Tooltip>
                            )}

                            {values[3] && (
                              <Tooltip
                                label={"Email inviata in data " + values[3]}
                              >
                                <Circle fontSize={10} size={5} bg={"green.500"}>
                                  <EmailIcon fontSize={10} color={"white"} />
                                </Circle>
                              </Tooltip>
                            )}
                            {values[5] && (
                              <Tooltip label="Sincronizzazione google attiva">
                                <Circle fontSize={10} size={5} bg={"green.500"}>
                                  <CgGoogle size={12} color={"white"} />
                                </Circle>
                              </Tooltip>
                            )}
                            {values[4].emptyCalendarAlertSent ||
                            values[4].threeMonthAlertSent ? (
                              <Tooltip
                                label={
                                  <VStack alignItems={"flex-start"} py={2}>
                                    {values[4].emptyCalendarAlertSent && (
                                      <Text>
                                        Calendario prossimo mese vuoto!
                                        <br />
                                        <small>
                                          Inviato in data:{" "}
                                          {new Date(
                                            values[4].emptyCalendarAlertDate
                                          ).toLocaleDateString()}
                                        </small>
                                      </Text>
                                    )}
                                    {values[4].emptyCalendarAlertSent &&
                                    values[4].threeMonthAlertSent ? (
                                      <Divider />
                                    ) : null}
                                    {values[4].threeMonthAlertSent && (
                                      <Text>
                                        Accesso operatore più di 3 mesi fa
                                        <br />
                                        <small>
                                          Inviato in data:{" "}
                                          {new Date(
                                            values[4].threeMonthAlertDate
                                          ).toLocaleDateString()}
                                        </small>
                                      </Text>
                                    )}
                                  </VStack>
                                }
                              >
                                <Circle
                                  fontSize={10}
                                  size={5}
                                  bg={"yellow.500"}
                                >
                                  <IoWarning fontSize={12} color={"white"} />
                                </Circle>
                              </Tooltip>
                            ) : null}

                            {/* <Link
                              href={cell.value[1]}
                              isExternal
                              whiteSpace="nowrap"
                              _hover={{
                                opacity: 0.7,
                              }}
                            >
                              Vai <ExternalLinkIcon mb="1" />
                            </Link> */}
                            {/* <Button
                            colorScheme={"blackAlpha"}
                            size={"xs"}
                            variant="outline"
                            onClick={() => {
                              window.open(cell.value[1], "_blank");
                            }}
                          >
                            Visualizza
                          </Button> */}
                          </HStack>
                        );
                      } else if (cell.column.Header === "CMS") {
                        data = (
                          <Link href={cell.value} isExternal>
                            Vai <ExternalLinkIcon mx="2px" />
                          </Link>
                        );
                      } else if (cell.column.Header === "CHECK") {
                        data = (
                          <Checkbox
                            style={{ paddingLeft: 16, width: 10 }}
                            /* {...getCheckboxProps({
                            value: row.cells[0].value,
                          })} */
                            onChange={(e) => {
                              let index = selected.indexOf(row.cells[0].value);

                              if (index === -1 && e.target.checked) {
                                let newSelection = [...selected];
                                newSelection.push(row.cells[0].value);
                                setSelected(newSelection);
                              } else if (index !== -1 && !e.target.checked) {
                                let newSelection = [...selected];
                                newSelection.splice(index, 1);
                                setSelected(newSelection);
                              }
                            }}
                            isChecked={
                              selected.includes(row.cells[0].value)
                                ? true
                                : false
                            }
                            //defaultChecked={cell.value[2]}
                            colorScheme="brandScheme"
                          />
                        );
                      } else if (cell.column.Header === "ID") {
                        data = null;
                      } else {
                        data = (
                          <Text
                            fontSize="sm"
                            fontWeight="700"
                            whiteSpace="nowrap"
                          >
                            {cell.value}
                          </Text>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          className={
                            hiddenColSM.includes(String(cell.column.Header))
                              ? "hidden-sm"
                              : null
                          }
                          key={index}
                          fontSize={{ sm: "14px" }}
                          //minW={{ sm: "10px",  md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          style={{ display: index === 0 ? "none" : undefined }}
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Flex>
        <Flex
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          paddingX={5}
          style={{ borderTopWidth: 1 }}
          paddingY={1}
        >
          <Flex flexDirection={"row"}>
            <Button
              mx={2}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>{" "}
            <Button
              mx={2}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Precedente
            </Button>{" "}
            <Button mx={2} onClick={() => nextPage()} disabled={!canNextPage}>
              Prossima
            </Button>{" "}
            <Button
              mx={2}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Flex>{" "}
          <Badge width={120} mx={2}>
            Pagina{" "}
            <strong>
              {pageIndex + 1} di {pageOptions.length}
            </strong>{" "}
          </Badge>
          {/* <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: "50px" }}
            />
          </span>{" "} */}
          {/*  <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Elementi {pageSize}
              </option>
            ))}
          </select> */}
        </Flex>
        {!props.loading && page.length < 1 && (
          <Flex
            w="100%"
            p={6}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Text>Nessun risultato soddisfa la tua richiesta</Text>
            <Button
              mt={6}
              onClick={() => {
                onSearchChange("");
                setFilters([]);
              }}
            >
              Cancella filtro
            </Button>
          </Flex>
        )}
        {!tableData.length && props.loading ? (
          <>
            <Skeleton opacity={0.7} height={"60px"} mb={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} my={1} />
            <Skeleton opacity={0.7} height={"60px"} mt={1} mb={4} />
          </>
        ) : null}
        {props.loading && <Loading />}
      </Card>
      {selected?.length ? (
        <Flex
          align="center"
          pos="fixed"
          bottom={2}
          right={2}
          height={"70px"}
          flex={1}
          //marginLeft={{ sm: "0px", md: "-30px", lg: "-326px" }}
          /* width={"calc(100vw - 20px)"} */
          /* marginRight={"20px"} */
          borderRadius={20}
          justify="center"
          borderWidth={1}
          backdropFilter="blur(20px)"
          //bg="brand.400"
          //position="static"
        >
          <HStack px={6}>
            <Text mr={3} fontWeight={"bold"}>
              {selected.length} Selezionati
            </Text>
            <Button
              onClick={() => {
                setDrawerOpen(true);
              }}
              style={{ minHeight: "44px" }}
              colorScheme={"brand"}
            >
              MODIFICA CALENDARIO
            </Button>
            <Button
              onClick={() => {
                onOpen();
              }}
              variant="outline"
              style={{ minHeight: "44px" }}
              colorScheme={"brand"}
            >
              Operatori
            </Button>
          </HStack>
        </Flex>
      ) : null}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={2} pl={5} fontSize={"2xl"} fontWeight={"bold"} mt={1}>
            Operazioni per operatori
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Text fontSize={"xl"} fontWeight={"bold"} mt={4}>
                Invia link e Qr-code
              </Text>

              <Button
                mt={3}
                onClick={() => {
                  onClose();
                  props
                    .getQrEmail({ variables: { _ids: selected } })
                    .then((res) => {
                      onOpenSuccess();
                    });
                }}
                variant="outline"
                colorScheme={"black"}
              >
                Inoltra per Email <EmailIcon ml={2} />
              </Button>
            </>

            <Divider my={5} />

            <Text fontSize="xl" color="gray.800" fontWeight={"bold"}>
              Modifica Permessi
            </Text>
            <Flex mt={6} px={2} flexDirection={"row"} alignItems={"center"}>
              <TimeIcon fontSize={20} mr={2} />

              <Flex mx={2} flex={1} flexDirection={"column"}>
                <Text fontSize={"xs"} color="gray.600" fontWeight={"bold"}>
                  REAL-TIME - MANUALE
                </Text>
                <Text fontSize={"xs"} color="gray.600">
                  Gestione manuale, di aperture e chiusure straordinarie in
                  tempo reale
                </Text>
              </Flex>
              <Switch
                isChecked={permissions.manual}
                onChange={(e) => {
                  setPermissions({ ...permissions, manual: e.target.checked });
                }}
                mx={4}
                size={"lg"}
                colorScheme={"brand"}
              />
            </Flex>
            <Flex mt={4} px={2} flexDirection={"row"} alignItems={"center"}>
              <CalendarIcon fontSize={20} mr={2} />

              <Flex mx={2} flex={1} flexDirection={"column"}>
                <Text fontSize={"xs"} color="gray.600" fontWeight={"bold"}>
                  CALENDARIO
                </Text>
                <Text fontSize={"xs"} color="gray.600">
                  Gestione calendarizzata di aperture/chiusure attività
                </Text>
              </Flex>
              <Switch
                isChecked={permissions.calendar}
                onChange={(e) => {
                  setPermissions({
                    ...permissions,
                    calendar: e.target.checked,
                  });
                }}
                mx={4}
                size={"lg"}
                colorScheme={"brand"}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose} variant="ghost">
              Annulla
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => {
                props
                  .secureManage({
                    variables: {
                      _ids: selected,
                      secure: {
                        reNewCode: false,
                        permissions: {
                          manual: permissions.manual,
                          calendar: permissions.calendar,
                        },
                      },
                    },
                  })
                  .then((data) => {
                    setSelected([]);
                    successToast();
                  })
                  .catch(() => {
                    errorToast();
                  });

                onClose();
                setPermissions({
                  manual: false,
                  calendar: false,
                });
              }}
            >
              Salva
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenSuccess} onClose={onCloseSuccess}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody w={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Flex
              mt={4}
              px={2}
              w={"100%"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                fontSize={"xl"}
                fontWeight={"bold"}
                mt={4}
                textAlign={"center"}
              >
                Email inoltrate correttamente agli operatori!
              </Text>
              <CheckIcon ml={2} fontSize={"8xl"} color="green.500" />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand"
              onClick={() => {
                onCloseSuccess();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddRangeDrawer
        _ids={selected as string[]}
        addRange={props.addRange}
        open={drawerOpen}
        onClosed={() => {
          setDrawerOpen(false);
        }}
        onFinish={() => {
          successToast();
          setSelected([]);
        }}
        disableHours={props.type === "accomodation" ? true : false}
      />
    </>
  );
};

export { ResourceTable };
