import { useQuery } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { AccomodationReport } from "components/Accomodation/AccomodationReport";
import { PoiReport } from "components/Poi/PoiReport";
import { ACTUAL_AUTH } from "stores/db/auth";
import { ACCOMODATIONS_GET_STATS } from "stores/queries/accomodations";
import { POIS_GET_STATS } from "stores/queries/pois";

const Dashboard = () => {
  const { data: poiStatsData, loading: poiLoading } = useQuery(POIS_GET_STATS);
  const { data: accomodationStatsData, loading: accomodationLoading } =
    useQuery(ACCOMODATIONS_GET_STATS);

  return (
    <Box>
      <Stack spacing={4} maxW="1800px">
        <PoiReport
          statistics={poiStatsData?.adminPoiGetStatistics?.statistics}
          emailStatistics={poiStatsData?.adminPoiGetStatistics?.emailStatistics}
          googleStatistics={
            poiStatsData?.adminPoiGetStatistics?.googleStatistics
          }
          loading={poiLoading}
          area={ACTUAL_AUTH.area}
        />
        <AccomodationReport
          statistics={
            accomodationStatsData?.adminAccomodationGetStatistics?.statistics
          }
          emailStatistics={
            accomodationStatsData?.adminAccomodationGetStatistics
              ?.emailStatistics
          }
          loading={accomodationLoading}
          area={ACTUAL_AUTH.area}
        />
      </Stack>
    </Box>
  );
};

export { Dashboard };
