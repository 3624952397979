import React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  ACCOMODATIONS_DAYS,
  DataOpeningDays,
} from "stores/queries/accomodations";
import { OpeningTable } from "components/List/OpeningTable";
import { formatDate } from "libs/helpers";

const AccomodationTableOpening = () => {
  const [getDays, { loading, error, data }] = useLazyQuery(ACCOMODATIONS_DAYS);

  const getData = async (
    from: string,
    to: string
  ): Promise<DataOpeningDays> => {
    let days = await getDays({
      variables: {
        from: formatDate(new Date(from)),
        to: formatDate(new Date(to)),
      },
    });
    let data: DataOpeningDays = [];
    if (days?.data?.adminAccomodationsGetDays) {
      for (let i = 0; i < days?.data?.adminAccomodationsGetDays.length; i++) {
        const d = days?.data?.adminAccomodationsGetDays[i];

        let nameLocale =
          d.name?.find((n) => n.langIso === "it")?.value ||
          d.name[0]?.value ||
          "";

        if (nameLocale.length > 20) {
          nameLocale = nameLocale.slice(0, 20) + "...";
        }

        const name =
          nameLocale + " " + "*".repeat(d.stars) + " (" + d.beds + ")";

        data.push({
          _id: d._id,
          name,
          days: d.days,
          stars: d.stars,
          beds: d.beds,
          rooms: d.rooms,
          townId: d.townId,
        });
      }
    }
    return data;
  };

  return (
    <OpeningTable
      getData={getData}
      loading={loading}
      title={"Apertura Strutture Ricettive"}
      itemPath={"/accomodations_openings/"}
      icon="my_hotel"
      type="accomodation"
      scope="admin"
    />
  );
};

export { AccomodationTableOpening };
